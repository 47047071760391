import React from "react"
import { graphql } from "gatsby"
import "../stylesheets/general.css"
import { Layout, Content, TacticsCard, Subscribe } from "../components"
import { H1, TacticsList } from "../elements"

const ArchivePage = ({ data }) => {
  const tactics = data.allWordpressPost.nodes
  return (
    <Layout hasHeader={false}>
      <Content spacing>
        <H1 large>All Tactics</H1>
        <TacticsList>
          {tactics.map((tactic, index) => (
            <TacticsCard key={index} tactic={tactic} />
          ))}
        </TacticsList>
      </Content>
      <Subscribe />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWordpressPost {
      nodes {
        id
        author {
          name
        }
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 430, maxHeight: 215, quality: 100) {
                srcSet
              }
            }
          }
        }
        categories {
          name
        }
      }
    }
  }
`

export default ArchivePage
